import { User } from '@sentry/node'
import { ListingBroker } from '~/models'
import { PROPERTY_TYPES_COLORS } from './constants'

export function valuesToValueLabelOptions(
  values: { [key: string]: any } | string[]
) {
  const options: { value: string; label: string }[] = []
  if (Array.isArray(values)) {
    values.forEach((value) => {
      options.push({ value, label: value })
    })
  } else {
    Object.keys(values).forEach((value) => {
      const label = values[value]
      options.push({ value, label })
    })
  }
  return options
}

export function debounce(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export function slugify(...args: (string | number)[]): string {
  const value = args.join(' ')

  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+|\//g, '-')
}

export function getPropertyTypeClassName(type: string) {
  let bdgColor = PROPERTY_TYPES_COLORS.find(
    (config) => config.propertyType === type
  )
  return bdgColor?.className
}

export function tryParseJson(value: string) {
  try {
    return JSON.parse(value)
  } catch (error) {}
  return []
}

export function transformImageUrl(url: string) {
  // const extension = url.includes('images') ? '.jpg' : '.png'
  const extension = '.png'
  return url
    .replace(
      'https://storage.googleapis.com/resimplifi-associate',
      'https://us-central1-resimplifi-1566929291680.cloudfunctions.net/associate-transform-image'
      // 'http://localhost:1207'
    )
    .replace('.webp', extension)
}

export function getMaxSubstringLength(value: string, maxLength: number) {
  if (value.length <= maxLength) return value.length
  const lastSpaceIndex = value.lastIndexOf(' ', maxLength)
  if (lastSpaceIndex === -1) return maxLength
  return lastSpaceIndex
}

export function getBrokerMemberships(broker: ListingBroker | User) {
  return (
    broker.meta?.memberships
      ?.split(/ |,|\|/g)
      .filter((v: string) => v)
      .join(', ') || ''
  )
}
